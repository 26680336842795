import React from "react";
import { Text } from "@gusarov-studio/rubik-ui";
import { clsx } from "clsx";
import styles from "./FileType.module.scss";

interface FileTypeProps {
  title: string;
  reference?: string;
  onClick?: React.MouseEventHandler;
  icon?: React.ReactNode;
  disabled?: boolean;
}

function FileType(props: FileTypeProps) {
  const { title, onClick, icon, disabled } = props;

  return (
    <a
      className={clsx(styles["lFileType"], {
        [styles["lFileTypeDisabled"]]: disabled,
      })}
      href="#"
      onClick={disabled ? undefined : onClick}
    >
      <span className={styles["lFileType__icon"]}>{icon}</span>

      <span className={styles["lFileType__title"]}>
        <Text
          weight="semibold"
          truncate
        >
          {title}
        </Text>
      </span>
    </a>
  );
}

export { FileType };
