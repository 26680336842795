// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-badge--hqkyM{display:inline-flex;padding:6px 8px;background-color:var(--color-black);border-radius:6px}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/PromptSetup/Badge.module.scss"],"names":[],"mappings":"AAAA,gBACE,mBAAA,CACA,eAAA,CACA,mCAAA,CACA,iBAAA","sourcesContent":[".l-badge {\n  display: inline-flex;\n  padding: 6px 8px;\n  background-color: var(--color-black);\n  border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-badge": `l-badge--hqkyM`,
	"lBadge": `l-badge--hqkyM`
};
export default ___CSS_LOADER_EXPORT___;
