import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { Report } from "../../../types/Report";
import { createSelector } from "@reduxjs/toolkit";
import __ls from "../../../__localStore";
import type { RootState } from "../../../store";
import type { Prompt } from "../../../types/Prompt";

const reportsApi = createApi({
  reducerPath: "api.Reports",
  baseQuery: fetchBaseQuery({ baseUrl: "/static/mock-api" }),
  endpoints: (build) => ({
    getReports: build.query<Report[], void>({
      query: () => "/reports.json?getReports",
      // @ts-expect-error test-purpose
      transformResponse: __ls.createOnGet("__reports"),
    }),
  }),
});

const getReportsResult = reportsApi.endpoints.getReports.select();

const getReports = createSelector(getReportsResult, (reportsResult) => reportsResult.data ?? []);

const getReportById = createSelector(
  getReports,
  (rootState: RootState, reportId: string) => reportId,
  (reports: Report[], reportId) => reports.find((report) => report.id === reportId),
);

const selectors = {
  getReports,
  getReportById,
};

const { useGetReportsQuery } = reportsApi;

export { reportsApi, selectors, useGetReportsQuery };
