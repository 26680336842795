import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, Button, Stack, Text, Input, Alert } from "@gusarov-studio/rubik-ui";
import { accountExists } from "../requests/accountExists";
import { CenteredColumnLayout } from "../../../common/components/Layout";
import GoogleAuthContainer from "../containers/GoogleAuthContainer";
import Divider from "../components/Divider";
import { useTranslator } from "../../localization";
import { routesMap } from "../../../router/routesMap";
import type { AuthFormData } from "../types";

function GetStartedScreen() {
  const [serverError, setServerError] = useState<string>("");
  const {
    register,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm<AuthFormData>({
    reValidateMode: "onBlur",
  });
  const navigate = useNavigate();
  const i18n = useTranslator();

  const onGoogleAuthError = useCallback(() => {
    setServerError(i18n.t("errors.somethingWentWrong"));
  }, [i18n]);

  const continueWithEmail = useCallback(
    async (data: AuthFormData) => {
      try {
        const { email } = data;
        const userExists = await accountExists(email);
        if (userExists) {
          navigate(routesMap.login.url({ email }));
        } else {
          navigate(routesMap.signup.url({ email }));
        }
      } catch (e) {
        console.log(e);
      }
    },
    [navigate],
  );

  return (
    <CenteredColumnLayout
      termsOfUse={{
        title: i18n.t("common.termsOfUse"),
        href: "#",
      }}
      privacyPolicy={{
        title: i18n.t("common.privacyPolicy"),
        href: "#",
      }}
      copyright="© 2024 Lisapet.AI"
    >
      <Text
        style={{ textAlign: "center" }}
        block
        size="display-md"
        weight="semibold"
      >
        {i18n.t("auth.getEarlyAccess")}
      </Text>

      <Box
        marginTop="spacing-4xl"
        marginBottom="spacing-xl"
      >
        <GoogleAuthContainer onError={onGoogleAuthError} />
      </Box>

      <Divider message={i18n.t("auth.or")} />

      {serverError && (
        <Box marginTop="spacing-xl">
          <Alert variant="danger">
            <Text
              size="regular-sm"
              appearance="error"
            >
              {serverError}
            </Text>
          </Alert>
        </Box>
      )}

      <Box marginTop="spacing-xl">
        <form onSubmit={handleSubmit(continueWithEmail)}>
          <Stack gap="xs">
            <Text
              as="label"
              htmlFor="email"
              size="regular-sm"
              block
              truncate
            >
              {i18n.t("auth.emailLabel")}
            </Text>

            <Input
              id="email"
              type="email"
              invalid={Boolean(errors.email)}
              placeholder={i18n.t("auth.emailPlaceholder")}
              onInput={() => {
                if (errors.email) {
                  clearErrors("email");
                }
              }}
              {...register("email", {
                required: true,
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              })}
            />

            {errors.email && (
              <Text
                appearance="error"
                size="regular-sm"
                block
                truncate
              >
                {i18n.t("errors.invalidEmail")}
              </Text>
            )}
          </Stack>

          <Box marginTop="spacing-3xl">
            <Button
              variant="primary"
              type="submit"
              shape="pill"
              fullWidth
              size="40"
            >
              {i18n.t("auth.emailButton")}
            </Button>
          </Box>
        </form>
      </Box>
    </CenteredColumnLayout>
  );
}

export { GetStartedScreen };
