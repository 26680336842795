import React from "react";
import { Text } from "@gusarov-studio/rubik-ui";
import styles from "./Badge.module.scss";

function Badge(props: React.PropsWithChildren) {
  const { children } = props;

  return (
    <div className={styles["lBadge"]}>
      <Text
        size="regular-xxs"
        appearance="white"
        weight="semibold"
        truncate
      >
        {children}
      </Text>
    </div>
  );
}

export { Badge };
