import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import firebaseApp from "@lisapet/firebase";
import { trackUserCreation } from "../../analytics/gtm";

const provider = new GoogleAuthProvider();
const auth = getAuth(firebaseApp);

function signInWithGoogle() {
  return signInWithPopup(auth, provider).then(trackUserCreation);
}

export { signInWithGoogle };
