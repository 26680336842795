import React from "react";
import { createBrowserRouter, redirect } from "react-router-dom";
import { routesMap } from "./routesMap";
import { isUserAuthenticated } from "../modules/auth";
import { App } from "../app/App";
import { GetStartedScreen } from "../modules/auth/screens/GetStartedScreen";
import { SignupScreen } from "../modules/auth/screens/SignupScreen";
import { EditorScreen } from "../modules/editor/screens/EditorScreen";
import { LoginScreen } from "../modules/auth/screens/LoginScreen";
import { LogoutScreen } from "../modules/auth/screens/LogoutScreen";
import { SuccessAuthScreen } from "../modules/auth/screens/SuccessAuthScreen";
import { DialogExample } from "../modules/editor/screens/DialogExample";
import { EditFileScreen } from "../modules/editor/screens/EditFileScreen";
import { NewFileScreen } from "../modules/editor/screens/NewFileScreen";
import { CreateFileScreen } from "../modules/editor/screens/CreateFileScreen";
import { OnboardingFormScreen } from "../modules/onboardingQuestionare/screens/OnboardingFormScreen";
import { OnboardingSchedulingScreen } from "../modules/onboardingQuestionare/screens/OnboardingSchedulingScreen";

const router = createBrowserRouter([
  {
    path: routesMap.home.path,
    element: <App />,
    loader: async () => {
      const isAuth = await isUserAuthenticated();

      if (!isAuth) {
        return redirect(routesMap.login.url());
      }

      return true;
    },
    children: [
      {
        path: routesMap.editor.path,
        element: <EditorScreen />,
        children: [
          {
            path: routesMap.editFile.path,
            element: <EditFileScreen />,
          },
          {
            path: routesMap.newFile.path,
            element: <NewFileScreen />,
          },
          {
            path: routesMap.createFile.path,
            element: <CreateFileScreen />,
          },
          {
            path: `edit-dialog`,
            element: <DialogExample />,
          },
        ],
      },
    ],
  },
  {
    path: routesMap.getStarted.path,
    element: <GetStartedScreen />,
    loader: async () => {
      const isAuth = await isUserAuthenticated();

      if (isAuth) {
        return redirect(routesMap.successAuth.url());
      }

      return true;
    },
  },
  {
    path: routesMap.login.path,
    element: <LoginScreen />,
    loader: async () => {
      const isAuth = await isUserAuthenticated();

      if (isAuth) {
        return redirect(routesMap.successAuth.url());
      }

      return true;
    },
  },
  {
    path: routesMap.signup.path,
    element: <SignupScreen />,
    loader: async () => {
      const isAuth = await isUserAuthenticated();

      if (isAuth) {
        return redirect(routesMap.successAuth.url());
      }

      return true;
    },
  },
  {
    path: routesMap.logout.path,
    element: <LogoutScreen />,
  },
  {
    path: routesMap.successAuth.path,
    element: <SuccessAuthScreen />,
    loader: async () => {
      const isAuth = await isUserAuthenticated();

      if (!isAuth) {
        return redirect(routesMap.login.url());
      }

      return true;
    },
  },
  {
    path: routesMap.onboardingForm.path,
    element: <OnboardingFormScreen />,
    loader: async () => {
      const isAuth = await isUserAuthenticated();

      if (!isAuth) {
        return redirect(routesMap.login.url());
      }

      return true;
    },
  },
  {
    path: routesMap.onboardingScheduling.path,
    element: <OnboardingSchedulingScreen />,
    loader: async () => {
      const isAuth = await isUserAuthenticated();

      if (!isAuth) {
        return redirect(routesMap.login.url());
      }

      return true;
    },
  },
  {
    path: routesMap.termsOfUse.path,
    element: <div>Terms of use</div>,
  },
  {
    path: routesMap.privacyPolicy.path,
    element: <div>privacyPolicy</div>,
  },
]);

export { router };
