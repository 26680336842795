import React from "react";

import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@lisapet/ui";

interface TestDialogProps {
  onClose: () => void;
}

function TestDialog(props: TestDialogProps) {
  const { onClose } = props;

  return (
    <Dialog
      open={true}
      onOpenChange={onClose}
    >
      <DialogContent style={{ backgroundColor: "#F9FAFB" }}>
        <DialogHeader>
          <DialogTitle>Are you absolutely sure?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. This will permanently delete your account and remove your
            data from our servers.
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export { TestDialog };
