import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/hooks";
import { Box, Input, Icon, ThemesContext, Text, Button } from "@gusarov-studio/rubik-ui";
import { reports } from "../../reports";
import { dialogs } from "../../dialogs";

interface ReportsEditorProps {
  id: string;
}

function ReportsEditorContainer(props: ReportsEditorProps) {
  const { id } = props;
  const dispatch = useDispatch();
  const targetPrompt = useAppSelector((state) => reports.selectors.getReportById(state, id));
  const themeContext = useContext(ThemesContext);
  const { toggleTheme } = themeContext;

  return (
    <div style={{ maxHeight: "calc(100vh - 50px)", overflow: "auto" }}>
      <pre>{JSON.stringify(targetPrompt, null, 2)}</pre>

      <div>
        <Button
          onClick={() =>
            dispatch(
              dialogs.actions.open({
                dialogName: dialogs.dialogNames.TEST,
                props: {
                  onClose: () => dispatch(dialogs.actions.close()),
                },
              }),
            )
          }
        >
          Open dialog
        </Button>
      </div>

      <div
        style={{ width: "80%", margin: "0 auto", padding: "20px", background: "var(--bg-primary)" }}
      >
        <Button onClick={toggleTheme}>Change theme</Button>

        <Text size={"regular-md"}>Hello</Text>

        <Box marginTop="spacing-3xl">
          <Input
            size="sm"
            placeholder="placeholder"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="sm"
            placeholder="placeholder"
            prefix="with-prefix"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="sm"
            placeholder="placeholder"
            suffix="with-sufix"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="sm"
            suffix="sufix"
            prefix="prefix"
            placeholder="placeholder"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="sm"
            placeholder="placeholder"
            internalPrefix="with-prefix"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="sm"
            placeholder="placeholder"
            internalSuffix="with-suffix"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="sm"
            placeholder="placeholder"
            internalPrefix="internal-prefix"
            internalSuffix="internal-suffix"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="sm"
            placeholder="placeholder"
            prefix="prefix"
            suffix="suffix"
            internalPrefix={<Icon glyph="magnify" />}
            internalSuffix={<Icon glyph="close" />}
          />
        </Box>
      </div>

      <div
        style={{
          width: "80%",
          margin: "0 auto",
          backgroundColor: "var(--bg-primary)",
          padding: "20px",
        }}
        className={themeContext.invertedTheme}
      >
        <Text size="regular-lg">Text inside inverted theme</Text>
        <Box marginTop="spacing-3xl">
          <Input
            size="lg"
            placeholder="placeholder"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="lg"
            placeholder="placeholder"
            prefix="with-prefix"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="lg"
            placeholder="placeholder"
            suffix="with-sufix"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="lg"
            suffix="sufix"
            prefix="prefix"
            placeholder="placeholder"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="lg"
            placeholder="placeholder"
            internalPrefix="with-prefix"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="lg"
            placeholder="placeholder"
            internalSuffix="with-suffix"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="lg"
            placeholder="placeholder"
            internalPrefix="internal-prefix"
            internalSuffix="internal-suffix"
          />
        </Box>

        <Box marginTop="spacing-3xl">
          <Input
            size="lg"
            placeholder="placeholder"
            prefix="prefix"
            suffix="suffix"
            internalPrefix={<Icon glyph="magnify" />}
            internalSuffix={<Icon glyph="close" />}
          />
        </Box>
      </div>
    </div>
  );
}

export { ReportsEditorContainer };
