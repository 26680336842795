import React from "react";
import { Box, Stack, Input, Text, Button } from "@gusarov-studio/rubik-ui";
import styles from "./PromptConfiguration.module.scss";
import type { NewPrompt, Prompt } from "../../../../types/Prompt";
import { useForm } from "react-hook-form";
import type { PromptGroup } from "../../../../types/PromptGroup";

interface AiModel {
  title: string;
  value: string;
}

interface PromptConfigurationProps {
  prompt: Prompt | NewPrompt;
  onSubmit: (data: Prompt | NewPrompt) => void;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  models: AiModel[];
  promptGroups?: PromptGroup[];
}

function PromptConfiguration(props: PromptConfigurationProps) {
  const { prompt, disabled, onSubmit, onDelete, models, promptGroups } = props;

  const {
    register,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm<Prompt | NewPrompt>({
    reValidateMode: "onBlur",
    defaultValues: prompt,
  });

  return (
    <div className={styles["lPromptConfiguration"]}>
      <Box padding="spacing-xl">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap="sm">
            <Stack gap="sm">
              <Text
                htmlFor="name"
                as="label"
              >
                Name
              </Text>

              <Input
                id="name"
                {...register("name", {
                  required: "Required",
                })}
                onInput={() => {
                  if (errors.name) clearErrors("name");
                }}
              />

              {errors && errors.name && <Text appearance="error">{errors.name.message}</Text>}
            </Stack>

            <Stack gap="sm">
              <Text
                htmlFor="llvm"
                as="label"
              >
                llvm
              </Text>

              <select
                id="llvm"
                {...register("llvm")}
              >
                {models.map(({ value, title }) => (
                  <option
                    key={value}
                    value={value}
                  >
                    {title}
                  </option>
                ))}
              </select>

              {errors && errors.llvm && <Text appearance="error">{errors.llvm.toString()}</Text>}
            </Stack>

            {promptGroups && (
              <Stack gap="sm">
                <Text
                  htmlFor="promptGroupId"
                  as="label"
                >
                  promptGroupId
                </Text>

                <select
                  id="promptGroupId"
                  {...register("promptGroupId", {
                    required: "Required",
                  })}
                >
                  {promptGroups.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                    >
                      {option.title}
                    </option>
                  ))}
                </select>
              </Stack>
            )}

            <Button
              type="submit"
              disabled={disabled}
            >
              Save
            </Button>

            {onDelete && (
              <Button
                variant="outline-primary"
                disabled={disabled}
                onClick={onDelete}
              >
                DELETE
              </Button>
            )}
          </Stack>
        </form>
      </Box>
    </div>
  );
}

export { PromptConfiguration };
