import React from "react";
import { Text } from "@gusarov-studio/rubik-ui";

interface AvatarProps {
  src?: string | null;
  fallback: string;
  altText?: string;
}

// TODO: Remove and replace with avatar from design system
function Avatar(props: AvatarProps) {
  const { src, fallback, altText } = props;

  return (
    <>
      {src && (
        <img
          src={src}
          alt={altText}
        />
      )}
      {!src && <Text size="regular-sm">{fallback}</Text>}
    </>
  );
}

export { Avatar };
