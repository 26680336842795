import React, { useCallback } from "react";
import { PromptSetup, PromptConfiguration } from "../components/PromptSetup";
import { EditorLayout } from "../components/EditorLayout";
import { aiModels } from "../../../common/constants/aiModels";
import type { NewPrompt } from "../../../types/Prompt";
import { useSupportedAiModels } from "../hooks/useSupportedAiModels";
import { useAddPromptMutation } from "../../prompts";
import { useGetPromptGroupsQuery } from "../../promptGroups";
import { routesMap } from "../../../router/routesMap";
import { fileTypes } from "../../../common/constants/fileTypes";
import { useNavigate } from "react-router-dom";

function CreatePromptContainer() {
  const prompt: NewPrompt = {
    llvm: aiModels.OPENAI,
    name: "",
    promptGroupId: "",
  };
  const navigate = useNavigate();
  const { data: promptGroups, isLoading: isGroupsQueryLoading } = useGetPromptGroupsQuery();
  const [addPrompt, { isLoading: isAddProcessing }] = useAddPromptMutation();

  const onSubmit = useCallback(
    (prompt: NewPrompt) => {
      addPrompt(prompt).then(({ data }) => {
        navigate(
          routesMap.editFile.url({
            fileType: fileTypes.PROMPT,
            fileId: data!.id,
            fileName: data!.name,
          }),
        );
      });
    },
    [addPrompt, navigate],
  );

  const models = useSupportedAiModels();

  if (isGroupsQueryLoading) {
    return <div>Loading...</div>;
  }

  return (
    <EditorLayout>
      <PromptSetup
        prompt={prompt}
        sidebar={
          <PromptConfiguration
            prompt={prompt}
            onSubmit={onSubmit}
            models={models}
            disabled={isAddProcessing}
            promptGroups={promptGroups}
          />
        }
        disabled={isAddProcessing}
      />
    </EditorLayout>
  );
}

export default CreatePromptContainer;
