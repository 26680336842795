import React from "react";
import styles from "./PromptSetup.module.scss";
import { Text } from "@gusarov-studio/rubik-ui";
import { PromptPlayground } from "./PromptPlayground";
import type { Prompt, NewPrompt } from "../../../../types/Prompt";

interface PromptEditorProps {
  prompt: NewPrompt | Prompt;
  sidebar?: React.ReactNode;
  disabled?: boolean;
}

function PromptSetup(props: PromptEditorProps) {
  const { prompt, sidebar } = props;

  return (
    <div className={styles["lPromptSetup"]}>
      <div className={styles["lPromptSetup__controlPanel"]}>
        <div>
          <Text appearance="white">Replace me with button</Text>
        </div>

        <div>
          <Text appearance="white">Replace me with button</Text>
        </div>
      </div>

      <div className={styles["lPromptSetup__container"]}>
        <div className={styles["lPromptSetup__playgroundContainer"]}>
          <PromptPlayground />
        </div>

        {sidebar && <div className={styles["lPromptSetup__sidebar"]}>{sidebar}</div>}
      </div>
    </div>
  );
}

export { PromptSetup };
