import React, { useState } from "react";
import styles from "./PromptPlayground.module.scss";
import { Input, Button } from "@gusarov-studio/rubik-ui";
import { Badge } from "./Badge";
import { PromptEditor } from "./PromptEditor";

interface PromptPlaygroundProps {
  disabled?: boolean;
}

function PromptPlayground(props: PromptPlaygroundProps) {
  const { disabled } = props;
  const items = [1, 2, 3];
  const [variables, setVariables] = useState<string[]>(["variable", "text", "description"]);

  return (
    <div className={styles["lPromptPlayground"]}>
      {items.map((item, i) => (
        <div
          key={i}
          className={styles["lPromptPlayground__row"]}
        >
          <div className={styles["lPromptPlayground__info"]}>
            <Badge>System</Badge>
          </div>
          <div className={styles["lPromptPlayground__main"]}>
            <div className={styles["lPromptPlayground__message"]}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium autem culpa
              cumque deleniti dolores ea ex facilis harum illum ipsum minus necessitatibus omnis
              perspiciatis provident quaerat reiciendis rerum velit, veritatis.
            </div>
          </div>
          <div className={styles["lPromptPlayground__interaction"]}>
            <span>cls</span>
          </div>
        </div>
      ))}

      <div className={styles["lPromptPlayground__row"]}>
        <div className={styles["lPromptPlayground__info"]}>
          <Badge>Assistant</Badge>
        </div>
        <div className={styles["lPromptPlayground__main"]}>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <PromptEditor
              variables={variables}
              defaultValue="Known variable - {variable}, unknown variable - {example}"
            />

            <Input
              placeholder={"Type allowed variables: variableName1,variableName2"}
              onChange={(e) => {
                const target: HTMLInputElement = e.target as HTMLInputElement;

                const variables = target.value.split(",").map((variable) => variable.trim());

                setVariables(variables);
              }}
              defaultValue={variables.join(",")}
            />
          </div>
        </div>
        <div className={styles["lPromptPlayground__interaction"]}>
          <span>cls</span>
        </div>
      </div>

      <div className={styles["lPromptPlayground__row"]}>
        <div className={styles["lPromptPlayground__info"]}></div>

        <div className={styles["lPromptPlayground__main"]}>
          <Button
            onClick={console.log}
            fullWidth
            disabled={disabled}
          >
            New message
          </Button>
        </div>

        <div className={styles["lPromptPlayground__interaction"]}></div>
      </div>
    </div>
  );
}

export { PromptPlayground };
