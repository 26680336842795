import React, { useEffect } from "react";
import { editor } from "@lisapet/ui";
import { useCallback, useRef } from "react";
import { useMonaco } from "@monaco-editor/react";

const { Editor } = editor;

interface PromptEditorProps extends editor.EditorProps {
  variables: string[];
}

function getAvailableVariablesRegexp(variables: string[]) {
  return new RegExp(`\\{\\b(${variables.join("|")})\\b\\}`);
}

const LANG_NAME = "lPrompt";
const PROMPT_VARIABLE_TOKEN = "custom-keyword";
const PROMPT_EDITOR_THEME = "lPromptTheme";

function PromptEditor(props: PromptEditorProps) {
  const { onMount, defaultValue, variables, height = "100px", ...otherProps } = props;
  const editorRef = useRef(null);
  const variablesRef = useRef<string[]>(variables);
  const monaco = useMonaco();

  const setupHighlightAndAutocomplete = useCallback(
    (variables: string[]) => {
      if (!monaco) {
        return;
      }

      variablesRef.current = variables;
      monaco.languages.setMonarchTokensProvider(LANG_NAME, {
        tokenizer: {
          root: [[getAvailableVariablesRegexp(variables), PROMPT_VARIABLE_TOKEN]],
        },
      });
    },
    [monaco],
  );

  const onMountHandler: editor.EditorProps["onMount"] = useCallback(
    (editor, monacoInstance: ReturnType<typeof useMonaco>) => {
      editorRef.current = editor;

      const model = editor.getModel()!;

      monacoInstance!.languages.register({ id: LANG_NAME });
      monacoInstance!.editor.setModelLanguage(model, LANG_NAME);

      monacoInstance!.languages.registerCompletionItemProvider(LANG_NAME, {
        triggerCharacters: ["{"],

        provideCompletionItems: (model, position) => {
          const textBeforeCursor = model.getValueInRange({
            startLineNumber: position.lineNumber,
            startColumn: 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
          });

          // Show suggestions only if the last character is '{'
          const lastChar = textBeforeCursor.charAt(textBeforeCursor.length - 1);

          if (lastChar === "{") {
            return {
              suggestions: variablesRef.current.map((variableName) => ({
                label: variableName,
                kind: monacoInstance!.languages.CompletionItemKind.Text,
                insertText: `${variableName}}`, // Insert this when selected
                insertTextRules:
                  monacoInstance!.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              })),
            };
          }

          return { suggestions: [] };
        },
      });

      monacoInstance!.editor.defineTheme(PROMPT_EDITOR_THEME, {
        base: "vs", // Use 'vs-dark' for a dark theme
        inherit: true,
        rules: [
          {
            token: PROMPT_VARIABLE_TOKEN,
            fontStyle: "bold",
            foreground: "0000FF",
            background: "#FF0000",
          }, // Blue and bold for keywords
          // Add other custom styles here
        ],

        colors: {
          "editor.foreground": "#000000", // Set default editor foreground color
          "editor.background": "#FFFFFF", // Set default background color
        },
      });

      monacoInstance!.editor.setTheme(PROMPT_EDITOR_THEME);

      setupHighlightAndAutocomplete(variables);

      if (onMount) {
        onMount(editor, monacoInstance!);
      }
    },
    [],
  );

  useEffect(() => {
    setupHighlightAndAutocomplete(variables);
  }, [variables, setupHighlightAndAutocomplete]);

  return (
    <Editor
      height={height}
      defaultLanguage="plaintext"
      onMount={onMountHandler}
      {...otherProps}
      defaultValue={defaultValue}
      options={{
        lineNumbers: "off", // Hide line numbers
        glyphMargin: false, // Hide the glyph margin
        folding: false, // Disable folding controls
        minimap: { enabled: false }, // Optionally hide the minimap
        renderLineHighlight: "none", // Remove line highlight
        // readOnly: true, // Optional: you could use this to hide the status bar if needed
        wordWrap: "on", // Enables word wrapping
        wrappingStrategy: "advanced", // Optionally, can enhance wrapping performance for large files
        scrollBeyondLastLine: false, // Optional: Disable scrolling past the last line
      }}
    />
  );
}

export { PromptEditor };
