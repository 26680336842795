// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-navigation-accordion-trigger--FyQZr{padding:4px 0;display:flex;align-items:center;gap:4px}.l-navigation-accordion-trigger--appearance-default--TgQ3R{color:var(--text-primary)}.l-navigation-accordion-trigger--appearance-quaternary--LFrss{color:var(--text-quaternary)}.l-navigation-accordion-trigger__icon--EyooA{flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/modules/navigation/components/NavigationAccordionTrigger/NavigationAccordionTrigger.module.scss"],"names":[],"mappings":"AAAA,uCACE,aAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,2DACE,yBAAA,CAGF,8DACE,4BAAA,CAGF,6CACE,aAAA","sourcesContent":[".l-navigation-accordion-trigger {\n  padding: 4px 0;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n\n  &--appearance-default {\n    color: var(--text-primary);\n  }\n\n  &--appearance-quaternary {\n    color: var(--text-quaternary);\n  }\n\n  &__icon {\n    flex-shrink: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-navigation-accordion-trigger": `l-navigation-accordion-trigger--FyQZr`,
	"lNavigationAccordionTrigger": `l-navigation-accordion-trigger--FyQZr`,
	"l-navigation-accordion-trigger--appearance-default": `l-navigation-accordion-trigger--appearance-default--TgQ3R`,
	"lNavigationAccordionTriggerAppearanceDefault": `l-navigation-accordion-trigger--appearance-default--TgQ3R`,
	"l-navigation-accordion-trigger--appearance-quaternary": `l-navigation-accordion-trigger--appearance-quaternary--LFrss`,
	"lNavigationAccordionTriggerAppearanceQuaternary": `l-navigation-accordion-trigger--appearance-quaternary--LFrss`,
	"l-navigation-accordion-trigger__icon": `l-navigation-accordion-trigger__icon--EyooA`,
	"lNavigationAccordionTrigger__icon": `l-navigation-accordion-trigger__icon--EyooA`
};
export default ___CSS_LOADER_EXPORT___;
