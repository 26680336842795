import React, { useContext } from "react";
import { Input, Icon, ThemesContext } from "@gusarov-studio/rubik-ui";
import styles from "./SearchBar.module.scss";
import clsx from "clsx";
import { noop } from "../../../../common/utils/noop";

interface SearchBarProps {
  onSearch?: () => void;
}

function SearchBar(props: SearchBarProps) {
  const { onSearch = noop } = props;
  const { themes } = useContext(ThemesContext);

  return (
    <div className={clsx(styles["lSearchBar"], themes.dark)}>
      <Input
        size="sm"
        placeholder={"Search..."}
        internalPrefix={<Icon glyph="magnify" />}
        internalSuffix={<Icon glyph="close" />}
        onChange={onSearch}
      />
    </div>
  );
}

export { SearchBar };
