// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-prompt-setup--UXTUw{height:100%;display:flex;flex-direction:column}.l-prompt-setup__control-panel--d0EsS{padding:8px 16px;display:flex;justify-content:space-between;background-color:var(--fg-secondary_hover)}.l-prompt-setup__container--CnFWO{display:flex;height:100%;min-height:0}.l-prompt-setup__playground-container--UuYFH{height:100%;flex:1 1 100%;overflow-y:auto}.l-prompt-setup__sidebar--yb0LY{flex:0 0 360px;height:100%}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/PromptSetup/PromptSetup.module.scss"],"names":[],"mappings":"AAAA,uBACE,WAAA,CACA,YAAA,CACA,qBAAA,CAEA,sCACE,gBAAA,CACA,YAAA,CACA,6BAAA,CACA,0CAAA,CAGF,kCACE,YAAA,CACA,WAAA,CACA,YAAA,CAGF,6CACE,WAAA,CACA,aAAA,CACA,eAAA,CAGF,gCACE,cAAA,CAEA,WAAA","sourcesContent":[".l-prompt-setup {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  &__control-panel {\n    padding: 8px 16px;\n    display: flex;\n    justify-content: space-between;\n    background-color: var(--fg-secondary_hover);\n  }\n\n  &__container {\n    display: flex;\n    height: 100%;\n    min-height: 0;\n  }\n\n  &__playground-container {\n    height: 100%;\n    flex: 1 1 100%;\n    overflow-y: auto;\n  }\n\n  &__sidebar {\n    flex: 0 0 360px;\n    //width: 360px;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-prompt-setup": `l-prompt-setup--UXTUw`,
	"lPromptSetup": `l-prompt-setup--UXTUw`,
	"l-prompt-setup__control-panel": `l-prompt-setup__control-panel--d0EsS`,
	"lPromptSetup__controlPanel": `l-prompt-setup__control-panel--d0EsS`,
	"l-prompt-setup__container": `l-prompt-setup__container--CnFWO`,
	"lPromptSetup__container": `l-prompt-setup__container--CnFWO`,
	"l-prompt-setup__playground-container": `l-prompt-setup__playground-container--UuYFH`,
	"lPromptSetup__playgroundContainer": `l-prompt-setup__playground-container--UuYFH`,
	"l-prompt-setup__sidebar": `l-prompt-setup__sidebar--yb0LY`,
	"lPromptSetup__sidebar": `l-prompt-setup__sidebar--yb0LY`
};
export default ___CSS_LOADER_EXPORT___;
