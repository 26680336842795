// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-auth-divider--z3dWO{display:flex;flex-direction:row;border:none;font-size:16px;font-weight:400;line-height:24px;margin:0;align-items:center;justify-content:center;vertical-align:baseline;color:var(--color-gray-700)}.l-auth-divider--z3dWO::before{content:"";border-bottom:1px solid var(--color-gray-200);flex:1 0 auto;height:.5em;margin:0}.l-auth-divider--z3dWO::after{content:"";border-bottom:1px solid var(--color-gray-200);flex:1 0 auto;height:.5em;margin:0}.l-auth-divider--z3dWO span{padding:0 12px}`, "",{"version":3,"sources":["webpack://./src/modules/auth/components/Divider/index.module.scss"],"names":[],"mappings":"AAAA,uBACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,QAAA,CACA,kBAAA,CACA,sBAAA,CACA,uBAAA,CACA,2BAAA,CAEA,+BACE,UAAA,CACA,6CAAA,CACA,aAAA,CACA,WAAA,CACA,QAAA,CAGF,8BACE,UAAA,CACA,6CAAA,CACA,aAAA,CACA,WAAA,CACA,QAAA,CAGF,4BACE,cAAA","sourcesContent":[".l-auth-divider {\n  display: flex;\n  flex-direction: row;\n  border: none;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  margin: 0;\n  align-items: center;\n  justify-content: center;\n  vertical-align: baseline;\n  color: var(--color-gray-700);\n\n  &::before {\n    content: \"\";\n    border-bottom: 1px solid var(--color-gray-200);\n    flex: 1 0 auto;\n    height: 0.5em;\n    margin: 0;\n  }\n\n  &::after {\n    content: \"\";\n    border-bottom: 1px solid var(--color-gray-200);\n    flex: 1 0 auto;\n    height: 0.5em;\n    margin: 0;\n  }\n\n  span {\n    padding: 0 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-auth-divider": `l-auth-divider--z3dWO`,
	"lAuthDivider": `l-auth-divider--z3dWO`
};
export default ___CSS_LOADER_EXPORT___;
