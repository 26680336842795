import { useMemo } from "react";
import { useAppSelector } from "../../../store/hooks";
import { prompts } from "../../prompts";
import { reports } from "../../reports";
import type { EditorTab } from "../types";
import { editor } from "../";
import { tabTypes } from "../constants/tabTypes";
import { fileTypes } from "../../../common/constants/fileTypes";

function findById<T extends { id: string }>(items: T[], id: T["id"]) {
  return items.find((item) => item.id === id);
}

interface TabView extends EditorTab {
  name: string;
  glyph?: string;
}

const useActiveTabs = () => {
  const activeTabs = useAppSelector(editor.selectors.getTabs);
  const promptList = useAppSelector(prompts.selectors.getPrompts);
  const reportList = useAppSelector(reports.selectors.getReports);

  return useMemo(
    () =>
      activeTabs.reduce((result: TabView[], tab) => {
        if (tab.type === tabTypes.NEW) {
          result.push({
            ...tab,
            name: tab.name!,
          });

          return result;
        }

        if (tab.type === tabTypes.CREATE) {
          result.push({
            ...tab,
            name: tab.name!,
          });
        }

        switch (tab.fileType) {
          case fileTypes.PROMPT: {
            const targetPrompt = findById(promptList, tab.id);

            if (targetPrompt) {
              result.push({
                ...tab,
                name: targetPrompt.name,
                glyph: targetPrompt.llvm,
              });
            }

            break;
          }

          case fileTypes.REPORT: {
            const targetReport = findById(reportList, tab.id);

            if (targetReport) {
              result.push({
                ...tab,
                name: targetReport.title,
                glyph: "ple-chart",
              });
            }

            break;
          }
        }

        return result;
      }, []),
    [activeTabs, promptList, reportList],
  );
};

export { useActiveTabs };
