import React, { useContext, useEffect } from "react";
import { useParams, useNavigate, Outlet, useLocation } from "react-router-dom";
import { Text, Icon, BrandIcon, ThemesContext } from "@gusarov-studio/rubik-ui";
import { editor } from "../";
import { NavigationCompactContainer } from "../../navigation/containers/NavigationCompactContainer";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { useActiveTabs } from "../hooks/useActiveTabs";
import { routesMap } from "../../../router/routesMap";

import TopBar from "../../../common/components/TopBar";
import TopBarItem from "../../../common/components/TopBarItem";
import { tabTypes } from "../constants/tabTypes";

function EditorScreen() {
  const dispatch = useAppDispatch();
  const { fileId, fileType, fileName, view } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const themesContext = useContext(ThemesContext);

  const navigate = useNavigate();
  const activeTab = useAppSelector(editor.selectors.getActiveTab);
  const activeTabs = useActiveTabs();

  const navigateToTab = ({
    type,
    fileType,
    id,
    name,
  }: {
    type: string;
    fileType?: string;
    id: string;
    name: string;
  }) => {
    if (type === tabTypes.NEW) {
      navigate(routesMap.newFile.url());

      return;
    }

    if (type === tabTypes.CREATE) {
      navigate(routesMap.createFile.url({ fileType: fileType! }));

      return;
    }

    navigate(routesMap.editFile.url({ fileType: fileType!, fileId: id, fileName: name }));
  };

  const closeTab = (tabId: string) => {
    console.log(tabId);
    if (activeTab!.id === tabId) {
      const index = activeTabs.findIndex((tab) => tab.id === tabId);

      if (activeTabs.length === 1) {
        navigate(routesMap.home.url());
      } else if (index === 0 && activeTabs.length > 1) {
        const newActiveTab = activeTabs[index + 1];

        navigateToTab(newActiveTab);
      } else if (index === activeTabs.length - 1 && activeTabs.length > 1) {
        const newActiveTab = activeTabs[index - 1];

        navigateToTab(newActiveTab);
      } else {
        const newActiveTab = activeTabs[index - 1];

        navigateToTab(newActiveTab);
      }
    }

    dispatch(editor.actions.removeTab(tabId));
  };

  useEffect(() => {
    if (pathname === routesMap.newFile.url()) {
      dispatch(
        editor.actions.addTab({
          id: "new",
          type: tabTypes.NEW,
          name: "New file",
        }),
      );

      return;
    }

    if (pathname === routesMap.createFile.url({ fileType: fileType! })) {
      dispatch(
        editor.actions.addTab({
          id: `create-${fileType!}`,
          type: tabTypes.CREATE,
          name: "Create file",
          fileType: fileType,
        }),
      );

      return;
    }

    dispatch(
      editor.actions.addTab({
        id: fileId!,
        type: tabTypes.EDIT,
        fileType: fileType,
      }),
    );
  }, [fileId, fileType, dispatch, pathname]);

  return (
    <>
      <TopBar
        className={themesContext.themes.dark}
        startContent={<NavigationCompactContainer />}
      >
        {activeTabs.map((tab) => (
          <TopBarItem
            key={tab.id}
            active={Boolean(activeTab) && tab.id === activeTab!.id}
            onClick={() => {
              navigateToTab(tab);
            }}
            onClose={(event: React.MouseEvent) => {
              event.stopPropagation();
              closeTab(tab.id);
            }}
          >
            {tab.glyph && <Icon glyph={tab.glyph} />}
            {tab.glyph && <BrandIcon glyph={tab.glyph} />}

            <Text
              truncate
              appearance="inherit"
              size="regular-sm"
            >
              {tab.name}
            </Text>
          </TopBarItem>
        ))}
      </TopBar>

      <Outlet />
    </>
  );
}

export { EditorScreen };
