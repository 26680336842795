import React from "react";
import {
  prompts,
  useGetPromptsQuery,
  useUpdatePromptMutation,
  useDeletePromptMutation,
} from "../../prompts";
import { useAppSelector } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import { PromptConfiguration, PromptSetup } from "../components/PromptSetup";
import { useSupportedAiModels } from "../hooks/useSupportedAiModels";
import { useGetPromptGroupsQuery } from "../../promptGroups";
import { routesMap } from "../../../router/routesMap";

interface PromptEditorProps {
  id: string;
}

function PromptEditorContainer(props: PromptEditorProps) {
  const { id } = props;
  const { isLoading } = useGetPromptsQuery();
  const targetPrompt = useAppSelector((state) => prompts.selectors.getPromptById(state, id));
  const [updatePrompt, { isLoading: isUpdateProcessing }] = useUpdatePromptMutation();
  const [deletePrompt, { isLoading: isDeleteProcessing }] = useDeletePromptMutation();
  const { data: promptGroups, isLoading: isGroupsQueryLoading } = useGetPromptGroupsQuery();
  const navigate = useNavigate();

  const isDisabled = isUpdateProcessing || isDeleteProcessing || isGroupsQueryLoading || false;

  const onSubmit = <T,>(data: T) => {
    updatePrompt({
      id,
      ...data,
    });
  };

  const onDelete = () => {
    deletePrompt({
      promptId: id,
    }).then(() => {
      navigate(routesMap.newFile.url());
    });
  };

  const models = useSupportedAiModels();

  if (isLoading || !targetPrompt) {
    return <div>Loading....</div>;
  }

  return (
    <PromptSetup
      disabled={isDisabled}
      prompt={targetPrompt}
      sidebar={
        <PromptConfiguration
          key={id}
          prompt={targetPrompt}
          onSubmit={onSubmit}
          disabled={isDisabled}
          models={models}
          promptGroups={promptGroups}
          onDelete={onDelete}
        />
      }
    />
  );
}

export { PromptEditorContainer };
