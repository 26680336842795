import React from "react";
import clsx from "clsx";
import { Icon } from "@gusarov-studio/rubik-ui";
import styles from "./index.module.scss";

type TopBarItem = React.PropsWithChildren<{
  className?: string;
  active?: boolean;
  onClick?: React.MouseEventHandler;
  onClose?: React.MouseEventHandler;
}>;

function TopBarItem(props: TopBarItem) {
  const { active = false, onClose, className, onClick, children, ...otherProps } = props;

  const classes = clsx(styles["lTopBarItem"], className, {
    [styles["lTopBarItemActive"]]: active,
  });

  return (
    <div
      className={classes}
      onClick={onClick}
      {...otherProps}
    >
      <div className={styles["lTopBarItem__content"]}>{children}</div>

      {onClose && (
        <button
          className={styles["lTopBarItem__closeBtn"]}
          onClick={onClose}
        >
          <Icon
            glyph="close"
            size={20}
          />
        </button>
      )}
    </div>
  );
}

export default TopBarItem;
