import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, BrandIcon } from "@gusarov-studio/rubik-ui";
import { useTranslator } from "../../localization";
import { signInWithGoogle } from "../requests/signInWithGoogle";
import { routesMap } from "../../../router/routesMap";
import { noop } from "../../../common/utils/noop";

interface GoogleAuthProps {
  onError?: <T>(error: T) => void;
  onSuccess?: () => void;
}

function GoogleAuthContainer(props: GoogleAuthProps) {
  const { onError = noop, onSuccess = noop } = props;
  const navigate = useNavigate();
  const i18n = useTranslator();

  const continueWithGoogle = useCallback(async () => {
    try {
      await signInWithGoogle();
      onSuccess();
    } catch (e) {
      onError(e);
    }
  }, [navigate, onError, onSuccess]);

  return (
    <Button
      fullWidth
      variant="outline-primary"
      size="40"
      onClick={continueWithGoogle}
    >
      <BrandIcon
        size={24}
        glyph="google"
      />
      <span style={{ marginLeft: "12px" }}>{i18n.t("auth.googleButton")}</span>
    </Button>
  );
}

export default GoogleAuthContainer;
