// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-file-type--CpTCU{display:flex;flex-direction:column;flex:1 0 0;border:1px solid var(--border-primary);background-color:var(--bg-primary);border-radius:6px;max-width:171px;min-width:171px;overflow:hidden;cursor:pointer}.l-file-type--disabled--AkXB7{cursor:not-allowed}.l-file-type--CpTCU:not(.l-file-type--disabled--AkXB7):hover{box-shadow:0 8px 16px 0 rgba(0,0,0,.25)}.l-file-type__title--PUQax{background-color:var(--bg-secondary_alt);padding:0 16px;height:56px;display:flex;justify-content:center;align-items:center}.l-file-type__icon--eccQn{padding:0 16px;height:152px;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/FileSelector/FileType.module.scss"],"names":[],"mappings":"AAAA,oBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,sCAAA,CACA,kCAAA,CACA,iBAAA,CACA,eAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CAEA,8BACE,kBAAA,CAGF,6DACE,uCAAA,CAGF,2BACE,wCAAA,CACA,cAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,0BACE,cAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".l-file-type {\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 0;\n  border: 1px solid var(--border-primary);\n  background-color: var(--bg-primary);\n  border-radius: 6px;\n  max-width: 171px;\n  min-width: 171px;\n  overflow: hidden;\n  cursor: pointer;\n\n  &--disabled {\n    cursor: not-allowed;\n  }\n\n  &:not(&--disabled):hover {\n    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);\n  }\n\n  &__title {\n    background-color: var(--bg-secondary_alt);\n    padding: 0 16px;\n    height: 56px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &__icon {\n    padding: 0 16px;\n    height: 152px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-file-type": `l-file-type--CpTCU`,
	"lFileType": `l-file-type--CpTCU`,
	"l-file-type--disabled": `l-file-type--disabled--AkXB7`,
	"lFileTypeDisabled": `l-file-type--disabled--AkXB7`,
	"l-file-type__title": `l-file-type__title--PUQax`,
	"lFileType__title": `l-file-type__title--PUQax`,
	"l-file-type__icon": `l-file-type__icon--eccQn`,
	"lFileType__icon": `l-file-type__icon--eccQn`
};
export default ___CSS_LOADER_EXPORT___;
