// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-editor-layout--jBRuI{height:calc(100% - 48px);background-color:var(--bg-quaternary)}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/EditorLayout/EditorLayout.module.scss"],"names":[],"mappings":"AAAA,wBAEE,wBAAA,CAEA,qCAAA","sourcesContent":[".l-editor-layout {\n  $navigationHeight: 48px;\n  height: calc(100% - $navigationHeight);\n\n  background-color: var(--bg-quaternary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-editor-layout": `l-editor-layout--jBRuI`,
	"lEditorLayout": `l-editor-layout--jBRuI`
};
export default ___CSS_LOADER_EXPORT___;
