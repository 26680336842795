import { getAuth } from "firebase/auth";

function isUserAuthenticated(): Promise<boolean> {
  return new Promise((resolve) => {
    const auth = getAuth();

    auth.authStateReady().then(() => {
      if (auth.currentUser) {
        resolve(true);
      }

      resolve(false);
    });
  });
}

export { isUserAuthenticated };
