import React, { useContext } from "react";
import clsx from "clsx";
import styles from "./NavigationHeader.module.scss";
import { Icon, Text, ThemesContext } from "@gusarov-studio/rubik-ui";

interface NavigationHeaderProps {
  title?: string;
  compact?: boolean;
  onClick: React.MouseEventHandler;
}

function NavigationHeader(props: NavigationHeaderProps) {
  const themesContext = useContext(ThemesContext);
  const { title = "", compact = false, onClick } = props;

  return (
    <div
      className={clsx(styles["lNavigationHeader"], themesContext.themes.dark, {
        [styles["lNavigationHeaderCompact"]]: compact,
      })}
    >
      <div
        className={styles["lNavigationHeader__buttonWrapper"]}
        onClick={onClick}
      >
        <Icon
          size="20"
          glyph={compact ? "sidebar-expand" : "sidebar-collapse"}
        />
      </div>

      {!compact && (
        <div className={styles["lNavigationHeader__title"]}>
          <Text
            size="regular-md"
            weight="semibold"
            appearance="white"
            block
            truncate
          >
            {title}
          </Text>
        </div>
      )}
    </div>
  );
}

export { NavigationHeader };
