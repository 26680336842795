const trackUserCreation = (userCredential) => {
  const { user } = userCredential;
  const { metadata } = user;
  const { creationTime, lastSignInTime } = metadata;

  if (creationTime === lastSignInTime) {
    window.dataLayer.push({ event: "auth.user_created" });
  }

  return userCredential;
};

export { trackUserCreation };
