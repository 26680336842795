// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-prompt-playground--VIk8t{display:flex;flex-direction:column;padding:32px 0;gap:12px;width:100%;max-width:922px;margin:0 auto}.l-prompt-playground__row--GjVSZ{display:flex;align-items:stretch}.l-prompt-playground__info--SYkZp{flex:0 0 85px;padding-top:8px;display:flex;justify-content:flex-end;align-items:flex-start}.l-prompt-playground__main--sXWB3{width:100%;flex:1 1 100%;margin-left:16px;margin-right:8px}.l-prompt-playground__message--_yq4h{border-radius:6px;padding:12px 16px;background-color:var(--bg-primary)}.l-prompt-playground__interaction--iF2r1{flex:0 0 48px;display:flex;align-items:flex-end;padding-bottom:6px}`, "",{"version":3,"sources":["webpack://./src/modules/editor/components/PromptSetup/PromptPlayground.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,qBAAA,CACA,cAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CAEA,iCACE,YAAA,CACA,mBAAA,CAGF,kCACE,aAAA,CACA,eAAA,CACA,YAAA,CACA,wBAAA,CACA,sBAAA,CAGF,kCACE,UAAA,CACA,aAAA,CACA,gBAAA,CACA,gBAAA,CAGF,qCACE,iBAAA,CACA,iBAAA,CACA,kCAAA,CAGF,yCACE,aAAA,CACA,YAAA,CACA,oBAAA,CACA,kBAAA","sourcesContent":[".l-prompt-playground {\n  display: flex;\n  flex-direction: column;\n  padding: 32px 0;\n  gap: 12px;\n  width: 100%;\n  max-width: 922px;\n  margin: 0 auto;\n\n  &__row {\n    display: flex;\n    align-items: stretch;\n  }\n\n  &__info {\n    flex: 0 0 85px;\n    padding-top: 8px;\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-start;\n  }\n\n  &__main {\n    width: 100%;\n    flex: 1 1 100%;\n    margin-left: 16px;\n    margin-right: 8px;\n  }\n\n  &__message {\n    border-radius: 6px;\n    padding: 12px 16px;\n    background-color: var(--bg-primary);\n  }\n\n  &__interaction {\n    flex: 0 0 48px;\n    display: flex;\n    align-items: flex-end;\n    padding-bottom: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"l-prompt-playground": `l-prompt-playground--VIk8t`,
	"lPromptPlayground": `l-prompt-playground--VIk8t`,
	"l-prompt-playground__row": `l-prompt-playground__row--GjVSZ`,
	"lPromptPlayground__row": `l-prompt-playground__row--GjVSZ`,
	"l-prompt-playground__info": `l-prompt-playground__info--SYkZp`,
	"lPromptPlayground__info": `l-prompt-playground__info--SYkZp`,
	"l-prompt-playground__main": `l-prompt-playground__main--sXWB3`,
	"lPromptPlayground__main": `l-prompt-playground__main--sXWB3`,
	"l-prompt-playground__message": `l-prompt-playground__message--_yq4h`,
	"lPromptPlayground__message": `l-prompt-playground__message--_yq4h`,
	"l-prompt-playground__interaction": `l-prompt-playground__interaction--iF2r1`,
	"lPromptPlayground__interaction": `l-prompt-playground__interaction--iF2r1`
};
export default ___CSS_LOADER_EXPORT___;
