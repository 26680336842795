import React from "react";
import { BrandIcon } from "@gusarov-studio/rubik-ui";
import { Icon } from "@gusarov-studio/rubik-ui";
import { useNavigate } from "react-router-dom";
import { fileTypes } from "../../../common/constants/fileTypes";
import { FileSelector, FileType } from "../components/FileSelector";
import { EditorLayout } from "../components/EditorLayout";
import { routesMap } from "../../../router/routesMap";

function NewFileScreen() {
  const navigate = useNavigate();

  const items = [
    {
      type: fileTypes.PROMPT,
      title: "New Prompt",
      icon: (
        <BrandIcon
          glyph="openai"
          size={56}
        />
      ),
    },
    {
      type: fileTypes.DATASET,
      title: "New Dataset",
      disabled: true,
      icon: (
        <Icon
          glyph="database"
          size={56}
          color="#A230FC"
        />
      ),
    },
    {
      type: fileTypes.TEST,
      title: "New Test",
      disabled: true,
      icon: (
        <Icon
          glyph="copy-check"
          size={56}
          color="#187DDC"
        />
      ),
    },
    {
      type: fileTypes.TEST_SUITE,
      title: "New Test Suite",
      disabled: true,
      icon: (
        <Icon
          glyph="copy-check"
          size={56}
          color="#EC8F5B"
        />
      ),
    },
    {
      type: fileTypes.REPORT,
      title: "New Report",
      disabled: true,
      icon: (
        <Icon
          glyph="ple-chart"
          size={56}
          color="#D84A1A"
        />
      ),
    },
  ];

  return (
    <EditorLayout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          padding: "0 20px",
        }}
      >
        <FileSelector>
          {items.map(({ type, title, icon, disabled }) => (
            <FileType
              key={type}
              title={title}
              icon={icon}
              disabled={disabled}
              onClick={() => {
                navigate(routesMap.createFile.url({ fileType: type }));
              }}
            />
          ))}
        </FileSelector>
      </div>
    </EditorLayout>
  );
}

export { NewFileScreen };
