import React from "react";
import clsx from "clsx";
import { Icon, BrandIcon } from "@gusarov-studio/rubik-ui";
import styles from "./NavigationActionList.module.scss";
import type { ListItem } from "../../types";

interface NavigationActionListProps {
  onClick: (event: React.MouseEvent, item: ListItem) => void;
  onDoubleClick: (event: React.MouseEvent, item: ListItem) => void;
  items: ListItem[];
  selectedItems: ListItem[];
}

function NavigationActionList(props: NavigationActionListProps) {
  const { onClick, onDoubleClick, items, selectedItems } = props;

  return (
    <div className={styles["lNavigationActionList"]}>
      {items.map((item) => (
        <a
          className={clsx(styles["lNavigationActionList__listItem"], {
            [styles["lNavigationActionList__listItemSelected"]]: selectedItems.includes(item),
          })}
          onClick={(event) => {
            event.preventDefault();
            onClick(event, item);
          }}
          onDoubleClick={(event) => onDoubleClick(event, item)}
          key={item.id}
          href="#"
        >
          {item.glyph && <Icon glyph={item.glyph} />}
          {item.brand && <BrandIcon glyph={item.brand} />}
          <span className={styles["lNavigationActionList__listItemText"]}>{item.name}</span>
        </a>
      ))}
    </div>
  );
}

export { NavigationActionList };
